import React, { useEffect } from 'react';
import './Support.css';
import { Helmet } from 'react-helmet';

function Support() {

    useEffect(()=> window.scrollTo(0, 0), []);

    const canonicalurl = window.location.href;

  return (
    <div className='main-support'>
        <Helmet>
            <link rel="canonical" href={canonicalurl} />
        </Helmet>
        <div className="container mt-4">
            <h2 className="fs-4 body-font-bold text-center">How Can We Help You?</h2>
            <div className="full-content mt-5">
                <p className="mb-3 fs-4 body-font-semibold">Account and Profile Support</p>
                <ol className='terms-ol'>
                    <li>
                        <p className="mb-0 body-font-semibold">How do I create my profile?</p>
                        <p className="mb-4">Visit our Sign-Up page, fill in the required details, and complete your profile to start searching for potential matches.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">I forgot my password. What should I do?</p>
                        <p className="mb-4">Click on "Forgot Password" on the login page and follow the steps to reset your password.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Can I edit my profile after I create it?</p>
                        <p className="mb-4">Yes, you can update your profile by logging in and navigating to the 'Edit Profile' section.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">How do I delete my account?</p>
                        <p className="mb-4">If you wish to delete your account, go to your profile settings and select 'Delete Account.' <span className="body-font-semibold">Please note that once your account is deleted, it cannot be restored.</span></p>
                    </li>
                </ol>
                <p className="mb-3 fs-4 body-font-semibold">Match and Meet Support</p>
                <ol className='terms-ol'>
                    <li>
                        <p className="mb-0 body-font-semibold">How do I send a match request?</p>
                        <p className="mb-4">Browse profiles that meet your preferences and click the 'Send Request' button. If the other person accepts, you will be notified, and a Google Meet will be arranged.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">What happens after a match is made?</p>
                        <p className="mb-4">Once a match is accepted by both parties, we will schedule a Google Meet for you and your guardians to get introduced.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Can I cancel a scheduled Google Meet?</p>
                        <p className="mb-4">Yes, please notify us at least 24 hours before the scheduled meeting by contacting our support team, and we will reschedule.</p>
                    </li>
                </ol>
                <p className="mb-3 fs-4 body-font-semibold">Privacy and Security</p>
                <ol className='terms-ol'>
                    <li>
                        <p className="mb-0 body-font-semibold">How is my data protected?</p>
                        <p className="mb-4">Your privacy is our priority. We take extensive measures to safeguard your personal information, ensuring it is only shared with your consent during the matchmaking process. Learn more in our Privacy Policy.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Can other users see my contact information?</p>
                        <p className="mb-4">No, your contact details remain private unless you choose to share them during the Google Meet.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">What should I do if I experience inappropriate behavior from another user?</p>
                        <p className="mb-4">Report any inappropriate behavior by clicking the 'Report' button on the user's profile or contacting our support team directly. We take all reports seriously and will investigate promptly.</p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
  )
}

export default Support;