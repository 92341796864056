import React, { useCallback, useEffect, useState } from 'react';
import './Detail.css';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';
import Signupcomp from '../../Components/Signupcomp/Signupcomp';
import { Helmet } from 'react-helmet';

function Detail() {

    const { islogged } = useAuth();
    const { userid } = useAuth();
    const { encodeJSON, decodeJSON } = useAuth();
    const { login } = useAuth();
    const { profiledata } = useAuth();
    const { ranid } = useAuth();
    const { interprofile } = useAuth();
    const { acceptedreqs } = useAuth();
    const { verifiedIcon } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const [imgsrc, setImgsrc] = useState('');
    const [popdis, setPopdis] = useState('none');
    const [userdata, setUserdata] = useState({});
    const [isacce, setIsacce] = useState(false);
    const [isreqsent, setIsreqsent] = useState(false);
    const [isreqacc, setIsreqacc] = useState(false);
    const [isadmin, setIsadmin] = useState(false);
    const [admintype, setAdmintype] = useState(false);
    const [condel, setCondel] = useState(false);
    const [allreqs, setAllreqs] = useState([]);
    const [isverified, setIsverified] = useState(false);
    const [sucnoti, setSucnoti] = useState('');
    const [errnoti, setErrnoti] = useState('');
    const [reportdis, setReportdis] = useState('none');
    const [reporttxt, setReporttxt] = useState('');
    const [reportpop, setReportpop] = useState('none');

    const reportReasons = [
        "Fake profile",
        "Inappropriate content",
        "Offensive or abusive language",
        "Scam or fraudulent behavior",
        "Profile impersonation",
        "Harassment or stalking",
        "Spamming or solicitation",
        "Profile contains misleading information",
        "Age misrepresentation",
        "Marital status misrepresentation",
        "Sharing personal contact information publicly",
        "Profile violates terms of service",
        "Profile picture does not match the person",
        "Multiple profiles for the same person",
        "Suspicious behavior",
        "Inappropriate photos or videos",
        "Requesting money or gifts",
        "Already married",
        "Promoting services or businesses"
    ];

    useEffect(()=> window.scrollTo(0, 0), []);

    const canonicalurl = window.location.href;

    useEffect(()=> {
        const adlocprodat = localStorage.getItem('adlocprodat');
        if(adlocprodat && adlocprodat.length > 0){
            setIsadmin(true);
            const decodedAdmin = decodeJSON(adlocprodat);
            setAdmintype(decodedAdmin.usertype);
        }
    }, [decodeJSON])

    const imgFunc = (img) => {
        setImgsrc(img);
        setPopdis('flex')
    }

    const sucNotiFunc = (txt) => {
        setSucnoti(txt);
        setTimeout(() => {
            setSucnoti('');
        }, 3000);
    }

    const errNotiFunc = (txt) => {
        setErrnoti(txt);
        setTimeout(() => {
            setErrnoti('');
        }, 3000);
    }

    // useEffect(() => {
    //     console.log('htis is awesome and we aal kanod that ', acceptedreqs)
    // }, [acceptedreqs])

    const cancelFunc = (elm) => {
        return !elm.classList.contains("popimg") && setPopdis('none');
    }

    useEffect(()=>{
        fetch(`https://shiawedding.onrender.com/user/${id}`)
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            setUserdata(data)
            setIsverified(data.verified);
        })
        .catch(err => {
            console.log('error occured while getting the user with id ', id, err);
        })
    }, [id]);

    useEffect(()=> {
        if(!isadmin){
            if(userdata?.randomid && ranid !== userdata?.randomid){
                fetch(`https://shiawedding.onrender.com/viewcount/${userdata?.randomid}/${ranid}`).then(res => res.json()).catch(err => console.log('error occured while counting the view ', err));
            }
        }
    }, [userdata, ranid, isadmin]);

    const createdByFunc = (procre) => {
        if(procre === 'Son' || procre === 'Daughter'){
            return 'Parent';
        }else if(procre === 'Brother' || procre === 'Sister'){
            return 'Sibling';
        }else if(procre === 'Myself' || procre === 'Relative'){
            return procre;
        }else{
            return '';
        }
    }

    const saveFunc = () => {
        fetch(`https://shiawedding.onrender.com/save/${userid}/${id}`)
        .then(res => res.json()).then(data => {
            // console.log(data);
            localStorage.setItem('locprodat', encodeJSON(data.prodat));
            login();
        }).catch(err => console.log('error occured while saving the profile ', err));
    };

    const sendRequest = () => {
        // console.log(userid, userdata?._id);
        fetch(`https://shiawedding.onrender.com/sendrequest/${userid}/${userdata?._id}`).then(res => res.json()).then(data => {
            // console.log(data);
            localStorage.setItem('locprodat', encodeJSON(data.prodat));
            if(data.prodat.reqsent.includes(id)){
                sucNotiFunc('Request sent successfully!');
            }else{
                sucNotiFunc('Request canceled successfully!');
            }
            login();
        }).catch(err => {
            console.log('error occured while sending the request ', err);
            errNotiFunc('Something went wrong! Please try again later.')
        });
    }

    useEffect(()=> {
        if(acceptedreqs?.includes(userdata?._id)){
            setIsacce(true);
        }else if(userid === userdata?._id){
            setIsacce(true)
        }else{
            setIsacce(false);
        }
    }, [acceptedreqs, userdata, userid]);

    const sudoimg = [
        'https://shiawedding.onrender.com/images/myphotos-1717590986903-827400397-13405.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717590987062-427376524-7049.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717590987001-716912638-521.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717590987044-13723848-8441.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717591182318-495257918-26172.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717590987022-305134793-3433.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717591067865-578860996-1647.jpg'
    ];
    const sudoimgmale = [
        'https://shiawedding.onrender.com/images/myphotos-1717654439564-43310390-53325.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717654439857-359489580-2147860917.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717654439945-288177791-2148181503.jpg', 
        'https://shiawedding.onrender.com/images/myphotos-1717654439907-565232251-2147747867.jpg'
    ];

    const updateReqsent = useCallback((bool) => {
        setIsreqsent(bool);
    }, [setIsreqsent]);

    useEffect(()=> {
        if(interprofile && interprofile?.reqsent?.length > 0){
            if(interprofile.reqsent.includes(userdata?._id)){
                updateReqsent(true);
            }else{
                updateReqsent(false);
            }
        }
    }, [interprofile, updateReqsent, userdata?._id]);

    useEffect(()=> {
        if(interprofile && interprofile?.reqstatus?.length > 0){
            interprofile.reqstatus.forEach(pro => {
                if(pro.rid === userdata?._id && pro.status === 'accepted'){
                    setIsreqacc(pro);
                }
            });
        }
    }, [interprofile, userdata?._id]);

    useEffect(()=>{
        if(userdata && interprofile){
            if(interprofile?.reqaccepted?.includes(userdata?._id)){
                setIsreqsent(true)
                setIsreqacc(userdata?._id);
            }
        }
    }, [userdata, interprofile])

    // useEffect(()=> {console.log('this is check of req id ', isreqsent)}, [isreqsent]);

    // useEffect(()=> {
    //     if(userdata?.profileblur){
    //         console.log('the profileblur is true')
    //     }else{
    //         console.log('the profileblur is false');
    //     }
    // }, [userdata])

    const delFunc = () => {
        if(admintype === 'superadmin'){
            console.log('this is the delete funciton for the profile');
            fetch(`https://shiawedding.onrender.com/deleteprofile/${userdata?._id}`, {method: 'DELETE'}).then(res => res.json()).then(data => {
                // console.log(data);
                data.success? navigate(-1): console.log('success is not true')
            }).catch(err => console.log('error occured while deleting the profile of user ', err));
        }else{
            alert("You don't have permissino to delete profiles")
        }
    }

    useEffect(()=> {
        if(userdata?.reqreceived?.length > 0){
            Promise.all(userdata?.reqreceived.map(id => fetch(`https://shiawedding.onrender.com/profile/${id}`).then(res => res.json()))).then(data => {
                // console.log(data);
                setAllreqs(data.reverse());
            }).catch(err => console.log('error occured while getting all the received reqs ', err));
        }
    }, [userdata])

    const verifyFunc = () => {
        fetch(`https://shiawedding.onrender.com/verifyprofile/${userdata?._id}`).then(res => res.json()).then(data => {
            // console.log(data);
            if(data.success){
                setIsverified(data?.prodat?.verified);
            }
        }).catch(err=>{
            console.log('error occured while updating the verification of user ', err);
        })
    }

    const reportFunc = () => {
        if(reporttxt !== ''){
            fetch(`https://shiawedding.onrender.com/report/${ranid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    reporterid: ranid,
                    profileid: id,
                    reason: reporttxt
                })
            }).then(res => res.json()).then(data => {
                console.log(data);
                if(data.success){
                    setReportpop('none');
                    sucNotiFunc("Profile reported successfully")
                }else{
                    if(data.msg === 'Already reported by you'){
                        sucNotiFunc("Profile already reported by you");
                        setReportpop("none");
                    }else{
                        errNotiFunc("Something went wrong, try after some time");
                        setReportpop("none")
                    }
                }
            }).catch(err => console.log('error occured while reporting a profile ', err));
        }
    }

  return (
    <div className='main-detail pb-5'>
        <Helmet>
            <link rel="canonical" href={canonicalurl} />
        </Helmet>
        <div className="container p-0">
            <p className="backbtn-detail mb-0 max-content cursor-pointer" onClick={()=> navigate(-1)}><i className="fas fa-arrow-left fs-5 text-white"></i></p>
        </div>
        <div className="container p-0">
            <p className="report-detail mb-0 max-content cursor-pointer" onClick={()=> setReportdis(reportdis === 'none'? 'block': 'none')}><i className="fas fa-ellipsis-v fs-5 text-white"></i></p>
            <p className="mb-0 report-para bg-white rounded-3 border p-2 px-3 text-danger body-font-bold text-center w-max-content cursor-pointer" style={{display: reportdis}} onClick={() => {setReportpop('flex'); setReportdis('none')}}>Report</p>
        </div>
        <div className="report-popup-wrap" style={{display: reportpop}}>
            <div className="cross-wrap text-end">
                <p className="mb-0 fs-4 w-max-content ms-auto cursor-pointer" onClick={()=> setReportpop('none')}><i className="fas fa-times text-white"></i></p>
            </div>
            <div className="report-popup rounded-brand bg-white py-4 pb-2 px-2">
                <h2 className="mb-3 fs-3 text-brand-pink text-center body-font-bold">Why are you reporting this profile?</h2>
                <div className="reasons-wrap mt-4 px-2">
                    {
                        reportReasons?.map((res, ind) => (
                            <p key={ind} onClick={(e) => setReporttxt(e.target.innerText)} className={`mb-3 border border-brand-pink rounded-brand p-2 px-3 cursor-pointer ${reporttxt === res && 'bg-brand-pink text-white'}`}>{res}</p>
                        ))
                    }
                </div>
                <div className="text-center py-2">
                    <button className="btn btn-brand-pink rounded-pill w-100 py-2 body-font-bold" onClick={reportFunc}>REPORT</button>
                </div>
            </div>
        </div>
        {
            islogged || isadmin? (
                <div className="all-detail-wrap">
                    <div className="main-detail-wrap mycontainer pb-4">
                        <div className="row justify-content-around p-0 m-0">
                            <div className="col-md-3 profile-n-contact p-0">
                                {
                                    isadmin? (
                                        <div className={`detail-img detail-profile-img img-fluid mb-3-wrap overflow-hidden`}>
                                            <img src={`https://shiawedding.onrender.com/images/${userdata?.profileimage?.split('/')[1]}`} alt="profile" className="img-fluid w-100" />
                                        </div>
                                    ):(
                                        <div className={`detail-img detail-profile-img img-fluid mb-3-wrap overflow-hidden ${userdata?.profileblur? 'temp-class': ''}`}>
                                            <img src={`https://shiawedding.onrender.com/images/${userdata?.profileimage?.split('/')[1]}`} alt="profile" className="img-fluid w-100" />
                                        </div>
                                    )
                                }
                                <div className="img-details p-2 px-3 mt-3">
                                    {
                                        isacce || isadmin? (
                                            <p className="name-detail mb-0 body-font-extrabold fs-5 text-capitalize lh-sm">{userdata?.firstname} {userdata?.lastname} <img src={verifiedIcon} alt="icon" className={`verified-img ms-1 ${!isverified && 'd-none'}`} /></p>
                                        ): (
                                            <p className="name-detail mb-0 body-font-extrabold fs-5 text-capitalize lh-sm">{userdata?.firstname ? (Array.from(userdata?.firstname).splice(0, 3).join('')): ''}<sub className='body-font-bold'>****</sub> <img src={verifiedIcon} alt="icon" className={`verified-img ms-1 ${!isverified && 'd-none'}`} /></p>
                                        )
                                    }
                                    {/* <p className="name-detail mb-0 body-font-extrabold fs-5 text-capitalize lh-sm">{userdata?.firstname} {userdata?.lastname}</p> */}
                                    <p className="proffession-detail mb-0 text-muted body-font-bold text-uppercase">{userdata?.jobtitle}</p>
                                    <div className="user-id">
                                        <p className="user-id-detail body-font-bold text-capitalize mb-2"><span className="body-font-medium">User ID:</span> {id}</p>
                                    </div>
                                    <div className="social-detail d-flex mt-2">
                                        {
                                            isacce || isadmin? (
                                                <div className="d-flex">
                                                    <a href={`https://instagram.com/${userdata?.instaprofile}`} target='_blank' rel="noreferrer" className="nav-link text-brand-pink me-2"><i className="fab fa-instagram fs-5"></i></a>
                                                    <a href={`https://facebook.com/${userdata?.faceprofile}`} target='_blank' rel="noreferrer" className="nav-link text-brand-pink me-2"><i className="fab fa-facebook fs-5"></i></a>
                                                    <a href={`https://x.com/${userdata?.twitprofile}`} target='_blank' rel="noreferrer" className="nav-link text-brand-pink me-2"><i className="fab fa-twitter fs-5"></i></a>
                                                </div>
                                            ):(
                                                <div className="d-flex">
                                                    <p className="nav-link text-brand-pink mb-0 me-2"><i className="fab fa-instagram fs-5"></i></p>
                                                    <p className="nav-link text-brand-pink mb-0 me-2"><i className="fab fa-facebook fs-5"></i></p>
                                                    <p className="nav-link text-brand-pink mb-0 me-2"><i className="fab fa-twitter fs-5"></i></p>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="contact-detail mt-4">
                                        {
                                            isacce || isadmin? (
                                                <a href={`tel:${userdata?.mobilenumber}`} className="phone-detail nav-link body-font-medium mb-2"><i className="fas fa-phone-alt text-brand-pink me-2"></i> +91 {userdata?.mobilenumber?.slice(0, 5)} {userdata?.mobilenumber?.slice(5)}</a>
                                            ): (
                                                <p className="mb-0 sudo-blur w-max-content phone-detail nav-link body-font-medium mb-2"><i className="fas fa-phone-alt text-brand-pink me-2"></i> +91 09876 54321</p>
                                            )
                                        }
                                        {
                                            isacce || isadmin? (
                                                <a href={`mailto:${userdata?.email}`} className="email-detail nav-link body-font-medium mb-2"><i className="fas fa-envelope text-brand-pink me-2"></i> {userdata?.email}</a>
                                            ): (
                                                <p className="email-detail sudo-blur mb-0 w-max-content nav-link body-font-medium mb-2"><i className="fas fa-envelope text-brand-pink me-2"></i> someemail@gmail.com</p>
                                            )
                                        }
                                        <p className="address-detail body-font-medium text-capitalize mb-2"><i className="fas fa-map-marker-alt text-brand-pink me-2"></i> {userdata?.currentlocation}</p>
                                    </div>
                                    <div className="contact-detail mt-4">
                                        <p className="address-detail body-font-bold text-capitalize mb-2"><span className="body-font-medium">Profile Created by:</span> {userdata?.madebyus? 'Admin': createdByFunc(userdata?.createdfor)}</p>
                                    </div>
                                    {
                                        isadmin && (
                                            <div className="reqs-wrap">
                                                <p className="mb-2 reqs-heading body-font-bold fs-5">Requests</p>
                                                {
                                                    allreqs?.map((req, ind) => (
                                                        <Link to={`/user/${req.randomid}`} key={ind} className="mb-0 nav-link text-capitalize body-font-semibold">{req.firstname} {req.lastname}, {req.randomid}</Link>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        isadmin && (
                                            <div className={`${isverified ? 'd-none': 'd-block'}`}>
                                                <button className="btn btn-brand-pink rounded-3 p-2 px-3 body-font-semibold mt-4" onClick={verifyFunc}>Verify</button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-md-8 bg-brand-light-pink p-3 rounded-brand overflow-hidden">
                                <h2 className="heading">About Me</h2>
                                <div className="dobNGender d-flex">
                                    <p className="user-id-detail body-font-medium text-capitalize mb-2"><span className="body-font-mediumtext-brand-pink"><i className="fas fa-calendar-check me-2"></i></span> {userdata?.dob?.split('-').reverse().join('/')}</p>,
                                    <p className="user-id-detail body-font-medium text-capitalize mb-2 ms-2"><span className="body-font-mediumtext-brand-pink"><i className="fas fa-venus-mars me-2"></i></span>{userdata?.gender}</p>
                                </div>
                                <p className="mb-0 text-muted">
                                    {userdata?.description}
                                </p>
                                <div className="mt-4 more-details row">
                                    <div className="col-md-6 mb-3">
                                        <div className="proffessional-detail p-3 bg-white rounded-brand">
                                            <h2 className="heading fs-5">Social</h2>
                                            <p className="height-detail body-font-bold mb-0"><span className="body-font-medium">Marital Status:</span> {userdata?.maritalstatus}</p>
                                            <p className="weight-detail body-font-bold mb-0"><span className="body-font-medium">Mother Tongue:</span> {userdata?.mothertongue}</p>
                                            <p className="color-detail body-font-bold mb-0"><span className="body-font-medium">Community:</span> {userdata?.community}</p>
                                            <p className="color-detail body-font-bold mb-0"><span className="body-font-medium">Stay With Family:</span> {userdata?.staywithfamily? 'Yes': 'No'}</p>
                                            <p className="color-detail body-font-bold mb-0"><span className="body-font-medium">Native Place:</span> {userdata?.nativeplace}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="proffessional-detail p-3 bg-white rounded-brand">
                                            <h2 className="heading fs-5">Proffessional Detail</h2>
                                            <p className="company-detail body-font-bold text-capitalize mb-0"><span className="body-font-medium">Work in:</span> {userdata?.workin === 'Not working'? '...' : userdata?.workin }</p>
                                            {
                                                isacce || isadmin? (
                                                    <p className="company-detail body-font-bold text-capitalize mb-0"><span className="body-font-medium">Company:</span> {userdata?.companyname === 'Not working' || userdata?.companyname === 'Self Employed'? '...': userdata?.companyname}</p>
                                                ): (
                                                    <p className="company-detail sudo-blur w-max-content body-font-bold text-capitalize mb-0"><span className="body-font-medium">Company:</span> {userdata?.companyname === 'Not working' || userdata?.companyname === 'Self Employed'? '...': userdata?.companyname}</p>
                                                )
                                            }
                                            <p className="position-detail body-font-bold mb-0"><span className="body-font-medium">Position:</span> {userdata?.jobtitle === 'Not working'? '...': userdata?.jobtitle}</p>
                                            {
                                                isacce || isadmin? (
                                                    <p className="companylocation-detail body-font-bold mb-0"><span className="body-font-medium">Annual Income:</span> {userdata?.annualincome === 'Not working'? '...': userdata?.annualincome}</p>
                                                ):(
                                                    <p className="companylocation-detail d-flex body-font-bold mb-0"><span className="body-font-medium">Annual Income:</span> <span className='d-block body-font-bold sudo-blur ms-2'>INR 0 Lakh to 0 Lakh</span></p>
                                                )
                                            }
                                            <p className="companylocation-detail body-font-bold mb-0"><span className="body-font-medium">Father:</span> {userdata?.fatherstatus}, <span className="body-font-medium ms-1">Mother:</span> {userdata?.motherstatus}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="proffessional-detail p-3 bg-white rounded-brand">
                                            <h2 className="heading fs-5">Qualifications</h2>
                                            <p className="degree-detail body-font-bold mb-0"><span className="body-font-medium">Highest Qualification:</span> {userdata?.heighesteducation}</p>
                                            <p className="passout-detail body-font-bold mb-0"><span className="body-font-medium">Course:</span> {userdata?.course}</p>
                                            {
                                                isacce || isadmin? (
                                                    <p className="college-detail text-capitalize body-font-bold mb-0"><span className="body-font-medium">College:</span> {userdata?.collegename}</p>
                                                ): (
                                                    <p className="companylocation-detail d-flex body-font-bold mb-0"><span className="body-font-medium">College:</span> <span className='d-block body-font-bold sudo-blur ms-2'>Lorem, ipsum dolor.</span></p>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="proffessional-detail p-3 bg-white rounded-brand">
                                            <h2 className="heading fs-5">Preferance (Social)</h2>
                                            <p className="gender-detail body-font-bold mb-0"><span className="body-font-medium">Community:</span> {userdata?.preferencecommunity}</p>
                                            <p className="cast-detail body-font-bold mb-0"><span className="body-font-medium">Location:</span> {userdata?.preferencecity}, {userdata?.preferencecountry}</p>
                                            <p className="loation-detail body-font-bold mb-0"><span className="body-font-medium">Mrital Status:</span> {userdata?.preferencemaritalstatus}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-3 pt-0">
                                    <div className="row bg-white p-3 px-2 rounded-brand">
                                        <h2 className="heading fs-5">Preferance (Proffessional)</h2>
                                        <div className="col-md-3 col-6 rounded-brand-sm p-2">
                                            <div className="d-flex align-items-center">
                                                <p className="mb-0"><i className="fas fa-rupee-sign me-3 fs-2 text-brand-pink"></i> </p>
                                                <div>
                                                    <p className="mb-0 propref-head text-muted body-font-bold">Annual income</p>
                                                    <p className="mb-0 lh-sm body-font-medium">{userdata?.preferenceannualincome}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 rounded-brand-sm p-2">
                                            <div className="d-flex align-items-center">
                                                <p className="mb-0"><i className="fas fa-graduation-cap me-3 fs-2 text-brand-pink"></i> </p>
                                                <div>
                                                    <p className="mb-0 propref-head text-muted body-font-bold">Degree</p>
                                                    <p className="mb-0 lh-sm body-font-medium">{userdata?.preferenceeducation}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 rounded-brand-sm p-2">
                                            <div className="d-flex align-items-center">
                                                <p className="mb-0"><i className="fas fa-user-tie me-3 fs-2 text-brand-pink"></i> </p>
                                                <div>
                                                    <p className="mb-0 propref-head text-muted body-font-bold">Job Title</p>
                                                    <p className="mb-0 lh-sm body-font-medium">{userdata?.preferencejobtitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6 rounded-brand-sm p-2">
                                            <div className="d-flex align-items-center">
                                                <p className="mb-0"><i className="fas fa-briefcase me-3 fs-2 text-brand-pink"></i> </p>
                                                <div>
                                                    <p className="mb-0 propref-head text-muted body-font-bold">Work in</p>
                                                    <p className="mb-0 lh-sm body-font-medium">{userdata?.preferenceworkin}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="detail-images-wrap mt-4">
                                    {
                                        userdata?.myphotos?.length > 0 && (
                                            <h2 className="heading fs-5">My Images</h2>
                                        )
                                    }
                                    <div className="detail-images">
                                        {
                                            isacce || isadmin? (
                                                userdata?.myphotos?.map((photo, ind)=> (
                                                    <div key={ind} className={`img-raper mb-3 ${userdata?.profileblur? 'temp-class': ''}`}>
                                                        <img src={`https://shiawedding.onrender.com/images/${photo.split('/')[1]}`} alt="gallery" className="detail-img w-100 rounded-brand cursor-pointer" onClick={(e)=> imgFunc(e.target.src)} />
                                                    </div>
                                                ))
                                            ): (
                                                userdata?.gender === 'Female'? (
                                                    sudoimg.map((photo, ind)=> (
                                                        <div key={ind} className="img-raper sudo-blur-img mb-3">
                                                            <img src={photo} alt="gallery" className="detail-img w-100 rounded-brand cursor-pointer" onClick={(e)=> imgFunc(e.target.src)} />
                                                        </div>
                                                    ))
                                                ): (
                                                    sudoimgmale.map((photo, ind)=> (
                                                        <div key={ind} className="img-raper sudo-blur-img mb-3">
                                                            <img src={photo} alt="gallery" className="detail-img w-100 rounded-brand cursor-pointer" onClick={(e)=> imgFunc(e.target.src)} />
                                                        </div>
                                                    ))
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="popupWrap p-2" style={{display: popdis}} onClick={e => cancelFunc(e.target)}>
                                    <div className="max-content popup-wraper pt-3">
                                        <p className="img-popup-cross mb-0 text-end fs-1 text-white ms-auto">&times;</p>
                                        <img src={imgsrc} alt="popup" className="popimg w-100 img-fluid rounded-brand"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-btn-wrap">
                        {
                            isadmin?(
                                <div className="container row mx-auto d-flex bg-white p-3">
                                    <div className="col-6 p-1">
                                        {
                                            <p className="btn btn-outline-brand-pink rounded-pill w-100 mb-0" onClick={()=> admintype === 'superadmin'? setCondel(true): alert('Only admin can delete profiles')}><i className="fas fa-trash-alt me-2"></i> Delete</p>
                                        }
                                    </div>
                                    <div className="col-6 p-1">
                                        {
                                            <Link to={`/admin/edituser/${userdata?._id}`} className="mb-0 btn btn-brand-pink rounded-pill w-100"><i className="fas fa-pen me-2"></i>Edit</Link>
                                        }
                                        
                                    </div>
                                </div>
                            ): (
                                ranid === userdata?.randomid? (
                                    <div className="container row mx-auto d-flex bg-white p-3">
                                        <div className="col-6 p-1">
                                            {
                                                <p className="btn btn-outline-brand-pink rounded-pill w-100 mb-0" onClick={()=> admintype === 'superadmin'? setCondel(true): alert('Only admin can delete profiles')}><i className="fas fa-trash-alt me-2"></i> Delete</p>
                                            }
                                        </div>
                                        <div className="col-6 p-1">
                                            {
                                                <Link to={`/editprofile`} className="mb-0 btn btn-brand-pink rounded-pill w-100"><i className="fas fa-pen me-2"></i>Edit</Link>
                                            }
                                            
                                        </div>
                                    </div>
                                ):(
                                    <div className="container row mx-auto d-flex bg-white p-3">
                                        <div className="col-3 p-1">
                                            {
                                                profiledata?.savedpro?.includes(id)?(
                                                    <p onClick={saveFunc} className="mb-0 btn btn-outline-brand-pink rounded-pill w-100"><i className="fas fa-bookmark"></i></p>
                                                ):(
                                                    <p onClick={saveFunc} className="mb-0 btn btn-outline-brand-pink rounded-pill w-100"><i className="far fa-bookmark"></i></p>
                                                )
                                            }
                                        </div>
                                        <div className="col-9 p-1">
                                            {
                                                isreqsent? (
                                                    isreqacc? (
                                                        <p className="mb-0 btn btn-brand-pink rounded-pill w-100"><i className="fas fa-check me-2"></i>Accepted</p>
                                                    ):(
                                                        <p onClick={sendRequest} className="mb-0 btn btn-brand-pink rounded-pill w-100"><i className="fas fa-times me-2"></i>Cancel Request</p>
                                                    )
                                                ): (
                                                    <p onClick={sendRequest} className="mb-0 btn btn-brand-pink rounded-pill w-100"><i className="fas fa-user-plus me-2"></i>Send Request</p>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            ):(
                <div className="w-100">
                    <Signupcomp/>
                </div>
            )
        }
        <div className={`del-pop-wrap ${!condel && 'd-none'}`}>
            <div className="del-pop rounded-brand bg-white p-3 py-4">
                <p className="mb-0 body-font-semibold text-center">Do you really want to delete <span className="text-capitalize body-font-bold">{userdata?.firstname} {userdata?.lastname}</span>'s profile ?</p>
                <div className="row mt-4">
                    <div className="col-6">
                        <button className="w-100 btn btn-brand-pink p-2 px-3 text-uppercase rounded-3" onClick={()=> setCondel(false)}>NO</button>
                    </div>
                    <div className="col-6">
                        <button className="w-100 btn btn-outline-brand-pink p-2 px-3 text-uppercase rounded-3" onClick={delFunc}>YES</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="pop-notifi-wrap p-2">
            <p className="mb-0 px-3 py-2 border border-2 border-success bg-success-subtle text-success rounded-brand-sm body-font-bold w-100" style={{display: sucnoti === '' && 'none'}}>{sucnoti}</p>
            <p className="mb-0 px-3 py-2 border border-2 border-danger bg-danger-subtle text-danger rounded-brand-sm body-font-bold w-100" style={{display: errnoti === '' && 'none'}}>{errnoti}</p>
        </div>

    </div>
  )
}

export default Detail;