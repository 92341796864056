import React, { useEffect } from 'react';
import "./Disclaimer.css";
import { Helmet } from 'react-helmet';

function Disclaimer() {

    useEffect(() => window.scrollTo(0, 0), []);

    const canonicalurl = window.location.href;

  return (
    <div className='main-disclaimer'>
        <Helmet>
            <link rel="canonical" href={canonicalurl} />
        </Helmet>
        <div className="container mt-4">
            <h2 className="fs-4 body-font-bold text-center">Disclaimer</h2>
            <div className="full-content mt-5">
                <p className="mb-3">
                Azdawaj.com is a platform designed to help Shia Muslims find compatible matches. However, we do not verify user details, nor do we offer guarantees regarding the success of any matches.
                Azdawaj is not liable for any loss, harm, or dispute arising from interactions between users.
                Users should practice caution and use their judgment when sharing information or meeting with other users.
                </p>
                <p className="mt-3 mb-0 body-font-semibold fs-4">Safety Guidelines</p>
                <p className="mb-3">To ensure a safe and respectful experience for all users, please follow these guidelines</p>
                <ol className='terms-ol'>
                    <li>
                        <p className="mb-0 body-font-semibold">Profile Integrity</p>
                        <p className="mb-4">Always provide accurate information on your profile.</p>
                    </li>
                    <li>
                        <p className="mb-2 body-font-semibold">Guardians' Involvement</p>
                        <p className="mb-4">We encourage you to involve your guardians or trusted family members in all stages of the matchmaking process.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Use the Platform Safely</p>
                        <p className="mb-4">Do not share personal information such as home addresses, financial details, or passwords with other users.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Meet Safely</p>
                        <p className="mb-4">All initial meetings should be arranged through the platform via Google Meet. We do not recommend meeting in person without thoroughly vetting the other person.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Report Suspicious Activity</p>
                        <p className="mb-4">If you encounter any suspicious or inappropriate behavior, report it to us immediately so we can take appropriate action.</p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
  )
}

export default Disclaimer;