import React, { useEffect } from 'react';
import './About.css';
import { Helmet } from 'react-helmet';

function About() {

  useEffect(()=> window.scrollTo(0,0), []);

  const canonicalurl = window.location.href;

  return (
    <div className='main-about'>
      <Helmet>
        <link rel="canonical" href={canonicalurl} />
      </Helmet>
      <div className="main-about-head">
        <div className="main-about-head-wrap d-flex flex-column justify-content-center align-items-center text-white">
          <p className="mb-1 body-font-bold fs-4">ABOUT</p>
          <p className="main-about-heading mb-0 body-font-bold">Azdawaj</p>
        </div>
      </div>
      <div className="container row align-items-center mx-auto mt-5 pt-5">
        <div className="col-md-7">
          <p className="mb-3 body-font-bold fs-4 border-bottom border-3 border-brand-pink w-max-content pb-1">Welcome to Azdawaj.com</p>
          <p className="mb-3">
            Azdawaj is a matrimonial platform exclusively designed for the Shia Muslim community, where we focus on connecting individuals with shared values and traditions. Our goal is to simplify the process of finding a compatible life partner by offering a secure, respectful, and culturally aligned space for Shia boys and girls to meet and explore potential matches.
          Our Mission.
          </p>
        </div>
        <div className="col-md-5 about-img-right d-flex align-items-center justify-content-center mb-3">
          <img src="/assets/steps.png" alt="steps" className="img-fluid w-75" />
        </div>
      </div>
      <div className="container row flex-wrap-reverse align-items-center mx-auto mt-5 pt-5">
        <div className="col-md-5 about-img-right d-flex align-items-center justify-content-center mb-3">
          <img src="/assets/steps.png" alt="steps" className="img-fluid w-75" />
        </div>
        <div className="col-md-7">
          <p className="mb-3 body-font-bold fs-4 border-bottom border-3 border-brand-pink w-max-content pb-1">Our Mission</p>
          <p className="mb-3">
            At Azdawaj, we understand the importance of family, community, and religious values in marriage. Our mission is to bring Shia families closer through a transparent, trustworthy, and user-friendly platform that respects your traditions and prioritizes safety and privacy.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About;