import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Footer() {
  const {ranid} = useAuth();
  return (
    <div className='main-footer bg-brand-light-pink py-5 mt-5'>
      <div className="container row mx-auto pb-5 border-bottom border-brand-pink">
        <div className="col-md-12 mb-4">
          <img src="/assets/logo.webp" alt="logo" width="100px" className="img-fluid" />
        </div>
        <div className="col-md-4 mb-4">
          <p className="mb-0 text-muted">
          Azdawaj is more than just a matrimonial platform. It is a community-driven space where you can take the next step in your journey toward finding a life partner, with the guidance and involvement of your loved ones.
          </p>
          <p className="mb-2 fs-5 body-font-bold mt-4">Follow Us:</p>
          <a href='https://instagram.com/azdawaj.com_official' target='_black' className="nav-link mb-1"><span className="text-brand-purple"><i className="fab fa-instagram me-2 fs-5"></i></span>/ Azdawaj.com_official</a>
          <a href='https://youtube.com/@azdawaj.com_official?si=szkQr7dlIfbo9DFB' target='_black' className="nav-link mb-1"><span className="text-brand-purple"><i className="fab fa-youtube me-2 fs-5"></i></span>/ Azdawaj_india</a>
          <a href='https://twitter.com/azdawaj_com' target='_black' className="nav-link mb-1"><span className="text-brand-purple"><i className="fab fa-twitter me-2 fs-5"></i></span>/ Azdawaj_com</a>
          {/* <a href='' target='_black' className="nav-link mb-1"><span className="text-brand-purple"><i className="fab fa-linkedin me-2 fs-5"></i></span>/ Azdawaj</a> */}
        </div>
        <div className="col-md-2 mb-4">
          <p className="mb-0 fs-5 body-font-bold">Navigation</p>
          <Link to="/" className="nav-link fs-5">Home</Link>
          <Link to="/explore" className="nav-link fs-5">Explore</Link>
          <Link to="/saved" className="nav-link fs-5">Saved</Link>
          <Link to="/profile" className="nav-link fs-5">Profile</Link>
        </div>
        <div className="col-md-3 mb-4">
          <p className="mb-0 fs-5 body-font-bold">Usefull</p>
          <Link to={`/user/${ranid}`} className="nav-link fs-5">My Profile</Link>
          <Link to="/editprofile" className="nav-link fs-5">Edit Profile</Link>
          <Link to="/about-us" className="nav-link fs-5">About Us</Link>
          <Link to="/terms-and-conditions" className="nav-link fs-5">Terms & Conditoins</Link>
          <Link to="/privacy-policy" className="nav-link fs-5">Privacy Policy</Link>
          <Link to="/disclaimer" className="nav-link fs-5">Disclaimer</Link>
          <Link to="/customer-support" className="nav-link fs-5">Customer Support</Link>
          <Link to="/contact" className="nav-link fs-5">Contact Us</Link>
        </div>
        <div className="col-md-3 mb-4">
          <p className="mb-0 fs-5 body-font-bold">Contact Details</p>
          <div className="d-flex">
            <div className='me-2'>
              <p className="mb-0"><i className="fas fa-phone-alt text-brand-purple fs-6"></i></p>
            </div>
            <div>
              <a href='tel:+919987475735' className="mb-0 nav-link fs-6">+91 99874 75735</a>
            </div>
          </div>
          <div className="d-flex">
            <div className='me-2'>
              <p className="mb-0"><i className="fab fa-whatsapp text-brand-purple fs-6"></i></p>
            </div>
            <div>
              <a href='https://wa.me/+919987475735' className="mb-0 nav-link fs-6">+91 99874 75735</a>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className='me-2'>
              <p className="mb-0"><i className="fas fa-envelope text-brand-purple fs-6"></i></p>
            </div>
            <div>
              <a href='mailto:contact.azdawaj@gmail.com ' className="mb-0 nav-link fs-6">contact.azdawaj@gmail.com </a>
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className='me-2'>
              <p className="mb-0"><i className="fas fa-map-marker-alt text-brand-purple fs-6"></i></p>
            </div>
            <div>
              <a href='google.com' className="mb-0 nav-link fs-6">Mumbai, Maharashtra, India.</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-3 d-flex flex-wrap justify-content-between pb-5">
          <p className="mb-0 text-muted text-capitalize">All rights reserved @ <span className="fs-5"><Link to="/" className='nav-link text-brand-pink body-font-heading d-inline'>Azdawaj</Link></span></p>
          <Link to="/terms-and-conditions" className='text-muted '>Terms & Conditions</Link>
      </div>
    </div>
  )
}

export default Footer;