import React, { useState, useEffect } from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';
import { Helmet } from 'react-helmet';

function Login() {

    const { encodeJSON } = useAuth();
    const { login } = useAuth();

    const navigate = useNavigate();
    const [errmess, setErrmess] = useState('');
    const [popdis, setPopdis] = useState('none');
    const [donedisp, setDonedisp] = useState('none');
    const [email, setEmail] = useState('');
    const [loginform, setLoginform] = useState({
        email: '',
        password: ''
    });

    useEffect(()=> window.scrollTo(0, 0), []);

    const canonicalurl = window.location.href;

    const loginFunc = () => {
        fetch('https://shiawedding.onrender.com/login', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginform)
        }).then(res => res.json()).then(data => {
            // console.log(data);
            if(data.firstname){
                localStorage.setItem('locprodat', encodeJSON(data));
                const localpath = localStorage.getItem('lasloc');
                login();
                if(localpath){
                    navigate(localpath)
                    localStorage.removeItem('lasloc')
                }
            }else{
                setErrmess(data.msg);
                setTimeout(() => {
                    setErrmess('');
                }, 3000);
            }
        }).catch(err => console.log('error occured while logging in ', err));
    };

    const sendmailFunc = ()=> {
        // console.log('hello');
        setPopdis('flex');
        fetch(`https://shiawedding.onrender.com/passwordreset/link/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"email": email})
        }).then(res => res.json()).then(data => {
            // console.log(data);
            setDonedisp('flex');
        }).catch(err => console.log('error occured while sending the pass reset link ', err));
    }

  return (
    <div className='main-login'>
        <Helmet>
            <link rel="canonical" href={canonicalurl} />
        </Helmet>
        <div className="container pt-4">
            <div className="login-contianer mx-auto p-3 pt-4 mt-5">
                <p className="heading fs-3 body-font-bold mb-2 text-center">Login</p>
                <div className="form-inputs mt-5">
                    <p className="mb-2 body-font-bold ms-2">Email</p>
                    <input type="email" className="form-control shadow-none border rounded-3 p-2 px-3 mb-3" placeholder='Enter your email' value={loginform.email} onChange={(e)=> setLoginform(x => ({...x, email: e.target.value}))}/>
                    <p className="mb-2 body-font-bold ms-2">Password</p>
                    <input type="password" className="form-control shadow-none border rounded-3 p-2 px-3" placeholder='Enter your password' value={loginform.password} onChange={(e)=> setLoginform(x => ({...x, password: e.target.value}))}/>
                    <div className="d-flex align-items-center justify-content-between mb-4 mt-4">
                        <p className='text-brand-pink mb-0 text-decoration-underline cursor-pointer' onClick={()=> setPopdis('flex')}>Forget password?</p>
                        <button className="btn btn-brand-pink rounded-3 px-3 fs-5" onClick={loginFunc}>Login</button>
                    </div>
                </div>
            </div>
            <div className="text-center mt-4">
                <Link to="/singup" className='text-brand-pink mb-0'>New here? Sign up</Link>
            </div>
        </div>
        <div className="error-wrap w-100 px-2" style={{display: errmess === '' && 'none'}}>
            <div className="container rounded-brand-sm bg-danger-subtle d-flex align-items-center justify-content-between border border-danger p-3">
                <p className="m-0 p-0 body-font-bold">{errmess}</p>
                <p className="m-0 p-0 body-font-bold fs-3 lh-sm text-capitalize" onClick={()=> setErrmess('')}>&times;</p>
            </div>
        </div>

        <div className="forgetpass-pop-wrap" style={{display: popdis}}>
            <div className="forgetpass-popup bg-white p-3 pt-1">
                <p className="mb-0 text-end fs-1 lh-sm cursor-pointer" onClick={()=> setPopdis('none')}>&times;</p>
                <p className="mb-0 fs-4 text-center body-font-bold m-3">
                    Enter you registered email, password reset link will be sent on it.
                </p>
                <div className="mx-3">
                <input type="text" className='form-control border outline-none shadow-none rounded-3 p-2 px-3 mt-4' value={email} onChange={(e)=> setEmail(e.target.value)} placeholder='Your email' />
                </div>
                <div className="text-center mt-4">
                    <button className="btn btn-brand-pink px-3 rounded-brand fs-5 mb-3" onClick={sendmailFunc}>Send mail</button>
                </div>
            </div>
        </div>
        <div className="forgetpass-pop-wrap" style={{display: donedisp}}>
            <div className="forgetpass-popup bg-white p-3">
                <p className="mb-0 fs-4 text-center body-font-bold m-3">
                    Password reset link has been sent to your email
                </p>
                <div className="text-center mt-4">
                    <button className="btn btn-brand-pink px-3 rounded-brand fs-5 mb-3" onClick={()=> {setPopdis('none'); setDonedisp('none')}}>Done</button>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Login;